import { Loader } from '@googlemaps/js-api-loader';
import { openModal, closeModal } from './modal.js';
import { showProductButton } from './single-product.js';
import { checkoutFillRequiredUserFields } from './checkout.js';
import { getCookie } from './getCookie.js';
import { openModalBooking } from './booking.js';
import $ from 'jquery';

$(() => {
    const googleApiKey = window?.sage?.GOOGLE_API_KEY;
    const ajax_url = window?.sage?.ajax_url;
    const nonce = window?.sage?.nonce;
    var googleMapAutocomplete = false;
    var autocompleteSessionToken = false;
    var organizedAddress = {};

    const $userSelectedStore = $('.user-selected-store');
    const $userSelectedStoreAddress = $('.user-selected-store-address');
    const skeleton = `<span class="block animate-pulse bg-slate-200 h-4 rounded "></span>`;

    const loader = new Loader({
        apiKey: googleApiKey,
        version: 'weekly',
        // libraries: ['places'],
    });

    const getStoreOptions = () => {
        const { locality, sublocality, lat, lng } = organizedAddress;

        $.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
                action: 'get_store_options',
                nonce: nonce,
                locality: locality,
                sublocality: sublocality,
                lat: lat,
                lng: lng,
            },
            beforeSend: () => {
                $('#modal-container-content').html(
                    $('#modal-container-loading').html()
                );
            },
            success: function (response) {
                if (response.success) {
                    $('#modal-container-content').html(response.data.html);
                    googleMapAutocomplete = null;
                }
            },
            error: function (error) {
                console.log(error);
            },
        });
    };

    const googleMapPlaceChanged = () => {
        if (
            !googleMapAutocomplete ||
            typeof googleMapAutocomplete.getPlace !== 'function'
        ) {
            console.log('no autocomplete');
            return;
        }

        let place = googleMapAutocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
            window.alert('Please select one address!');
            return;
        }

        if (place.address_components) {
            let addressComponents = place.address_components;
            organizedAddress = [];

            organizedAddress['lat'] = place.geometry.location.lat();
            organizedAddress['lng'] = place.geometry.location.lng();

            for (let i = 0; i < addressComponents.length; i++) {
                let component = addressComponents[i];
                for (let j = 0; j < component.types.length; j++) {
                    let type = component.types[j];
                    if (!organizedAddress[type]) {
                        if (type == 'administrative_area_level_2') {
                            organizedAddress[type] = component.short_name;
                        } else {
                            organizedAddress[type] = component.long_name;
                        }

                        if (type == 'administrative_area_level_1') {
                            organizedAddress[
                                'administrative_area_level_1_short_name'
                            ] = component.short_name;
                        }
                    }
                }
            }

            autocompleteSessionToken = null;

            getStoreOptions();
        }
    };

    const initGoogleMapPlaces = () => {
        if (!googleMapAutocomplete) {
            loader.load().then(async () => {
                // 1) Dynamically import the 'places' library
                const { Autocomplete, AutocompleteSessionToken } =
                    await google.maps.importLibrary('places');

                let inputField = document.getElementById('select-store-input');

                inputField.addEventListener('input', function () {
                    if (!googleMapAutocomplete) {
                        googleMapAutocomplete = true;

                        // 2) Create a new session token
                        autocompleteSessionToken =
                            new AutocompleteSessionToken();

                        // 3) Initialize Autocomplete
                        googleMapAutocomplete = new Autocomplete(inputField, {
                            componentRestrictions: { country: 'nz' },
                            fields: [
                                'address_components',
                                'geometry',
                                'address_components.short_name',
                            ],
                            sessionToken: autocompleteSessionToken,
                        });

                        // 4) Listen for place changes
                        googleMapAutocomplete.addListener(
                            'place_changed',
                            googleMapPlaceChanged
                        );
                    }
                });
            });
        }
    };

    const setStoreCookie = (store_id, store_title, store_address) => {
        $userSelectedStore.html(skeleton);
        $userSelectedStoreAddress.html(skeleton);

        //Cookie Time
        var d = new Date();
        var expires = 7;
        d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();

        //Cookie Address
        var address_1 = 'wp_user_location_billing_address_1';
        var city = 'wp_user_location_billing_city';
        var postcode = 'wp_user_location_billing_postcode';
        var state_short = 'wp_user_location_billing_state';

        var address_value =
            organizedAddress['street_number'] + ' ' + organizedAddress['route'];
        var city_value = organizedAddress['locality'];
        var state_value_short =
            organizedAddress['administrative_area_level_1_short_name'];
        var postal_code_value = organizedAddress['postal_code'];

        document.cookie = `${address_1}=${address_value};${expires};path=/`;
        document.cookie = `${city}=${city_value};${expires};path=/`;
        document.cookie = `${postcode}=${postal_code_value};${expires};path=/`;
        document.cookie = `${state_short}=${state_value_short};${expires};path=/`;

        // Cookie Store
        const storeIdCookie = `storeID=${store_id}; expires=${new Date(
            'December 31, 9999'
        ).toUTCString()}; path=/`;
        const storeTitleCookie = `storeTitle=${store_title}; expires=${new Date(
            'December 31, 9999'
        ).toUTCString()}; path=/`;

        document.cookie = storeIdCookie;
        document.cookie = storeTitleCookie;

        setTimeout(() => {
            $userSelectedStore.html(store_title);
            $userSelectedStoreAddress.html(store_address);
        }, 1000);

        if (!getCookie('bookingDate')) {
            openModalBooking();
        } else {
            closeModal();
        }
    };

    $(document).on('click', '.open-modal-select-store', function () {
        const title = 'Select your store';
        googleMapAutocomplete = null;

        openModal(title);

        $.ajax({
            type: 'post',
            url: ajax_url,
            data: {
                action: 'get_select_store_options_modal',
                nonce: nonce,
            },
            success: function (response) {
                if (response.success) {
                    $('#modal-container-content').html(response.data.html);
                    initGoogleMapPlaces();
                }
            },
        });
    });

    $(document).on('click', '.select-store-apply', function () {
        const storeId = $(this).data('id');
        const storeTitle = $(this).data('title');
        const storeAddress = $(this).data('address');
        const storeEnquire = $(this).data('enquire');

        setStoreCookie(storeId, storeTitle, storeAddress);
        // if (storyIDCookie != store_id) {
        // }

        //Cookie TTime
        var d = new Date();
        var expires = 7;
        d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
        var expires = 'expires=' + d.toUTCString();

        document.cookie = `wp_user_empty_cart=yes;${expires};path=/`;

        if (
            ($('body').hasClass('single-product') && storeEnquire == 'yes') ||
            $('#single-product-enquire-form').length
        ) {
            location.reload();
            return;
        }

        showProductButton();
        checkoutFillRequiredUserFields();

        // check if is checkout page
        if ($('body.page-template-template-woo-checkout').length) {
            // reload checkout page
            location.reload();
        }
    });

    const showPosition = (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        organizedAddress = {
            lat: lat,
            lng: lng,
            locality: false,
            sublocality: false,
        };

        getStoreOptions();
    };

    $(document).on(
        'click',
        '.select-user-location-use-my-location',
        function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showPosition);
            } else {
                alert('Geolocation is not supported by this browser.');
            }
        }
    );
});
